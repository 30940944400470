import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { GoogleMap, Marker, InfoWindow, LoadScript } from '@react-google-maps/api'
import { faSearch } from "@fortawesome/free-solid-svg-icons"
import Geocode from "react-geocode";
import queryString from 'query-string'

import Page from "../components/layout/Page"
import PageHeader from "../components/layout/PageHeader"
import SEO from "../components/seo"
import BlueButton from "../components/buttons/BlueButton"
import TextField from "../components/fields/TextField"
import GoogleImage from "../components/GoogleImage";
import PhotoGalleryDialog from "../components/dialogs/PhotoGalleryDialog"

import { postData } from "../services/server"
import { google } from "../settings"
import { useCachedState } from "../hooks/useCachedState"
import { storage } from '../settings'

const SearchForm = styled.div`
  margin-top: 20px;

  label {
    display: block;
  }
  button {
    margin-left: 10px;
  }
`
const Info = styled.div`
  background: white;

  h3 {
    margin: 0px;
  }
`
const Photos = styled.div`
  margin: 15px 0px;
  display: flex;
`
const Photo = styled.div`
  padding: 5px;
  flex: 1;
  cursor: pointer;

  img {
    border-radius: 3px;
  }
`

Geocode.setApiKey(google.googleMapsKey);
Geocode.setLanguage("en");
Geocode.enableDebug();

const DEFLAT = 39.8097343;
const DEFLNG = -98.5556199;
const DEFZOOM = 4;

const MapComponent = (props) => {
  const [map, setMap] = React.useState(null)

  const [selected, setSelected] = useState({ coords: null, location: null});
  const [open, setOpen] = useState(false);
  const [photos, setPhotos] = useState(null);
  const [index, setIndex] = useState(0);

  const onLoad = React.useCallback(function callback(map) {
    // const bounds = new window.google.maps.LatLngBounds();
    // console.log("onLoad", bounds);
    // map.fitBounds(bounds);
    setMap(map)
  }, [])

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null)
  }, [])

  function onMarkerClick(e, location) {
    const coords = { lat: e.latLng.lat(), lng: e.latLng.lng() };

    setSelected({ 
      coords: coords, 
      location: location 
    });

    if (props.onMarker) {
      const center = map.getCenter();

      props.onMarker({lat: center.lat(), lng: center.lng()});
    }
  }
  function onMarkerClose() {
    setSelected({ coords: null, location: null});
  }
  function onPhoto(photos, index) {
    setPhotos(photos);
    setIndex(index);
    setOpen(true);
  }
  function onClosePhotos() {
    setPhotos(null);
    setIndex(0);
    setOpen(false);
  }

  /*
          lat: selected.location ? parseFloat(selected.location.latitude) : props.coords.lat, 
          lng: selected.location ? parseFloat(selected.location.longitude) : props.coords.lng 
  */

  return (
    <LoadScript
      id="script-loader"
      googleMapsApiKey={google.googleMapsKey}
    >
      <GoogleMap
        id='signmonkey-map'
        mapContainerStyle={{
          height: "500px",
          width: "100%"
        }}
        zoom={props.zoom}
        center={{ 
          lat: props.coords.lat, 
          lng: props.coords.lng 
        }}
        onCenterChanged={(e) => {
          if (map && props.onCoordsChanged) {
            const center = map.getCenter();
            const coords = {lat: center.lat(), lng: center.lng()};

            if (coords.lat != props.coords.lat || coords.lng != props.coords.lng)
              props.onCoordsChanged(coords);
          }
        }}
        onLoad={onLoad}
        onUnmount={onUnmount}        
      >
        {props.markers.map((marker, index) => (
            <Marker
              key={index} 
              position={{ lat: parseFloat(marker.latitude), lng: parseFloat(marker.longitude) }} 
              onClick={(e) => onMarkerClick(e, marker)}
            />
        ))}

        { selected.location && 
          <InfoWindow
            onCloseClick={onMarkerClose}
            position={{lat: selected.coords.lat, lng: selected.coords.lng}}
            options={{
              pixelOffset: new window.google.maps.Size(0, -25),
              shouldFocus: false,
              disableAutoPan: true
            }}
          >
            <Info>
              <h3>{selected.location.title}</h3>
              <p>{selected.location.address}</p>

              {(selected.location.photos.length > 0) ? 
                  <Photos>
                    {selected.location.photos.map((photo,index) => (
                        <Photo key={index} onClick={() => onPhoto(selected.location.photos, index)}>
                          <GoogleImage 
                            root
                            src={photo.url_thumb}  
                            required 
                            lazy
                            style={{
                              height: "75px"
                            }}
                          />
                        </Photo>
                    ))}
                  </Photos>
                :
                  <p>No Photos Found</p>
              }
            </Info>
          </InfoWindow>
        }
      </GoogleMap>
      {open &&
          <PhotoGalleryDialog 
              photos={photos}
              currentIndex={index}
              onClose={onClosePhotos}
          />
      }        
    </LoadScript> 
  )
}

const MapPage = () => {
  const [loading, setLoading] = useState(false);
  const [coords, setCoords] = useState({ lat: DEFLAT, lng: DEFLNG});
  const [zoom, setZoom] = useState(DEFZOOM);
  const [zipCode, setZipCode] = useState("");
  const [markers, setMarkers] = useState([]);
  const [error, setError] = useState("");

  useEffect(
    () => {
        if (!markers || markers.length === 0) {
          setLoading(true);

          postData("/api/map/locations", {
              lat: DEFLAT,
              lng: DEFLNG,
              distance: 5000
            },
            function(locations) {
                // transform the photos for the lightbox
                locations.forEach( (location) => {
                  location.photos.forEach( (photo) => {
                    photo.caption = location.title;
                    //photo.url_thumb = storage.url + photo.url_thumb;
                    photo.source = {
                      download: storage.url + photo.url,
                      fullscreen: storage.url + photo.url,
                      regular: storage.url + photo.url_thumb,
                      thumbnail: storage.url + photo.url_thumb,
                    }
                  });
                })

                setMarkers(locations);
            },
            function(error) {
                alert("An error occured loading signs for the map.");
            },
            function() {
                setLoading(false);
            }
          );
        }
    }, 
    []
  );

  useEffect(
    () => {
        const params = queryString.parse(typeof window !== `undefined` ? window.location.search : "")

        if (params.zipcode && params.zipcode.length > 0) {
          setZipCode(params.zipcode);
          onSearch(params.zipcode);
        }
    }, 
    []
  );

  function onZipCode(e) {
    setZipCode(e.target.value);
  }
  async function onSearch(zip) {
    setError("");
    setLoading(true);

    const response = await Geocode.fromAddress(zip || zipCode);    

    if (response && response.status && response.status == "OK") {
      const { lat, lng } = response.results[0].geometry.location;

      setCoords({ lat: lat, lng: lng});
      setZoom(8)
    }
    else {
      setError("Invalid zip code")
    }

    setLoading(false);
  }

  return (
    <>
      <SEO page="map" title="Sign Map" />
      <Page
        breadcrumb={[
            {title: "Home", url:"/"},
            {title: "Sign Map", url:null}
        ]}
      >
        <PageHeader title="Find one of our signs near You" />

        <MapComponent
          coords={coords}
          zoom={zoom}
          markers={markers}
          onMarker={(markerCoords) => {
            setCoords(markerCoords);
          }}
          onCoordsChanged={(newCoords) => {
            //console.log("onCoordsChanged", newCoords)
            //setCoords(newCoords);
          }}
          googleMapURL={"https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=" + google.googleMapsKey}
          loadingElement={<div style={{ height: `100%` }} />}
          containerElement={<div style={{ height: `500px` }} />}
          mapElement={<div style={{ height: `100%` }} />}
        />

        <SearchForm>
            <TextField 
              name="zip" 
              label="Your Zip Code"
              value={ zipCode } 
              wide
              onEnter={(e) => onSearch(e.target.value)}              
              onChange={onZipCode}            
            >
              <BlueButton 
                label="Search Map"
                icon={faSearch} 
                disabled={zipCode.length < 5}
                busy={loading}
                onClick={() => onSearch()}
              />
            </TextField>
        </SearchForm>
        {error.length > 0 && 
          <div style={{color:"red"}}>
            {error}
          </div>
        }
      </Page>
    </>
  )
}

export default MapPage